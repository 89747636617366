import { inject, observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import { IRoom } from "../../../models/Room";
import { IRoomStore } from "../../../stores/Room";
import Header from "../../molecules/Header";
import LoadingPage from "../Loading";
import styles from "./styles.module.scss";

interface IProps {
  roomStore: IRoomStore;
}

interface IRoomItemProps {
  room: IRoom;
}

export const RoomItem = (props: IRoomItemProps) => (
  <li>
    <Link to={`/rooms/${props.room.slug}`}>{props.room.name}</Link>
  </li>
);

@inject("roomStore")
@observer
class SelectRoomPage extends React.Component<IProps> {
  public render() {
    const { roomStore } = this.props;

    if (!roomStore.rooms.length) {
      return <LoadingPage />;
    }

    return (
      <React.Fragment>
        <Header />
        <div className={styles.content}>
          <ul>
            {roomStore.rooms.map(room => (
              <RoomItem key={room.slug} room={room} />
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default SelectRoomPage;
