import { inject, observer } from "mobx-react";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginPage from "./components/pages/Admin/Login";
import AdminPrefsPage from "./components/pages/Admin/Prefs";
import AdminReseveInfoPage from "./components/pages/Admin/ReserveInfo";
import AdminRoomPage from "./components/pages/Admin/Room";
import AdminTopPage from "./components/pages/Admin/Top";
import ConfirmPage from "./components/pages/Confirm";
import NoMatchPage from "./components/pages/NoMatch";
import RoomPage from "./components/pages/Room";
import SelectRoomPage from "./components/pages/SelectRoom";
import ThanksPage from "./components/pages/Thanks";
import { IRoomStore } from "./stores/Room";

interface IProps {
  roomStore: IRoomStore;
}

@inject("roomStore")
@observer
class Router extends React.Component<IProps> {
  public componentWillMount() {
    const { roomStore } = this.props;
    roomStore.fetchRooms();
  }

  public render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact={true} path="/" component={SelectRoomPage} />
          <Route exact={true} path="/rooms/:slug" component={RoomPage} />
          <Route
            exact={true}
            path="/rooms/:slug/confirm"
            component={ConfirmPage}
          />
          <Route
            exact={true}
            path="/rooms/:slug/thanks"
            component={ThanksPage}
          />
          <Route exact={true} path="/login" component={LoginPage} />
          <Route exact={true} path="/admin" component={AdminTopPage} />
          <Route
            exact={true}
            path="/admin/rooms/:slug"
            component={AdminRoomPage}
          />
          <Route
            exact={true}
            path="/admin/rooms/:slug/:gid"
            component={AdminReseveInfoPage}
          />
          <Route exact={true} path="/admin/prefs" component={AdminPrefsPage} />
          <Route component={NoMatchPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;
