import { inject, observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import AuthStore from "../../../stores/Auth";
import styles from "./styles.module.scss";

interface IProps {
  authStore: AuthStore;
}

@inject("authStore")
@observer
class AdminHeader extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  public handleSignOut() {
    this.props.authStore.signOut();
  }

  public render() {
    return (
      <header className={styles.header}>
        <h1 className={styles.header__title} data-test="title">
          <Link className={styles.header__link} to="/admin">
            管理画面
          </Link>
        </h1>
        <div className={styles.header__right}>
          <h2 data-test="displayName" className={styles.header__displayName}>
            {this.props.authStore.displayName
              ? this.props.authStore.displayName
              : "名称未設定"}
          </h2>
          <h3
            className={styles.pointer}
            onClick={this.handleSignOut}
            role="button"
            data-test="logout"
          >
            ログアウト
          </h3>
        </div>
      </header>
    );
  }
}

export default AdminHeader;
