import moment from "moment";
import "moment/locale/ja";
import * as React from "react";
import { IReserve } from "../../../models/Reserve";
import { IRoom } from "../../../models/Room";
import CalendarCell from "../../atoms/CalendarCell";
import styles from "./styles.module.scss";

interface IProps {
  date: moment.Moment;
  room: IRoom;
  reserves: IReserve[];
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  selected: moment.Moment | null;
}

const CalendarDay = (props: IProps) => {
  moment.locale("ja");
  const startAt = moment()
    .hours(props.room.openAtHours)
    .minutes(props.room.openAtMinutes);
  const closeAt = moment()
    .hours(props.room.closeAtHours)
    .minutes(props.room.closeAtMinutes);
  const openHours = closeAt.hours() - startAt.hours();
  // tslint:disable-next-line:prefer-array-literal
  const arr = Array.from(new Array(openHours).keys());

  const isReserved = (index: number) =>
    !!props.reserves.filter(
      r => r.startAt.toDate().getHours() === index + startAt.hours()
    ).length;

  const cellValue = (i: number) => {
    const date = moment(props.date);
    date.hours(i + startAt.hours());
    return date.toISOString();
  };

  const makeCalendar = (date: number) =>
    arr.map(i => {
      const innerIndex = props.room.openAtHours + i;
      const isSelected = props.selected
        ? props.selected.hours() === innerIndex &&
          date === props.selected.date()
        : false;
      return (
        <div
          className={styles.tableCell}
          key={i}
          data-test="cell"
          aria-disabled={isReserved(i) || isSelected}
        >
          <CalendarCell
            value={cellValue(i)}
            reserved={isReserved(i)}
            selected={isSelected}
            onClick={props.onClick}
          />
        </div>
      );
    });

  return (
    <div className={styles.tableDay}>
      <b className={styles.tableHead}>
        {props.date.format("ddd")}
        <br />
        {props.date.month() + 1}/{props.date.date()}
      </b>
      {makeCalendar(props.date.date())}
    </div>
  );
};

export default CalendarDay;
