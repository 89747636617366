import moment from "moment";
import * as React from "react";
import { IReserve } from "../../../models/Reserve";
import { IRoom } from "../../../models/Room";

interface IProps {
  selectedDateTime: moment.Moment;
  selectedHours: number;
  reserves: IReserve[];
  room: IRoom;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const UtilizationTimeSelector = (props: IProps) => {
  const openingHours = props.room.closeAtHours - props.room.openAtHours;
  let count = 0;
  for (let i = 0; i < openingHours; i += 1) {
    const cur = i + props.room.openAtHours;
    if (cur < props.selectedDateTime.hours()) {
      continue;
    }
    const matched = !!props.reserves.filter(
      r => r.startAt.toDate().getHours() === cur
    ).length;
    if (matched) {
      break;
    }
    count += 1;
  }
  // tslint:disable-next-line:prefer-array-literal
  const arr = Array.from(new Array(count).keys());
  return (
    <select onChange={props.onChange} value={props.selectedHours}>
      <option>選択してください</option>
      {arr.map(i => (
        <option key={i} value={i + 1}>
          {i + 1}時間
        </option>
      ))}
    </select>
  );
};

export default UtilizationTimeSelector;
