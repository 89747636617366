import { History } from "history";
import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { match, Redirect } from "react-router";
import { IRoutingParamsWithGID } from "../../../../models/RoutingParams";
import { IAuthStore } from "../../../../stores/Auth";
import { IReserveStore } from "../../../../stores/Reserve";
import { IRoomStore } from "../../../../stores/Room";
import { registrationMessagingClient } from "../../../../vendor/firebase";
import Button from "../../../atoms/Button";
import Label from "../../../atoms/Label";
import AdminHeader from "../../../molecules/AdminHeader";
import { Modal } from "../../../organisms/Modal";
import LoadingPage from "../../Loading";
import styles from "./styles.module.scss";

interface IProps {
  history: History;
  authStore: IAuthStore;
  roomStore: IRoomStore;
  reserveStore: IReserveStore;
  match: match;
}

interface IState {
  modalOpen: boolean;
}

@inject("authStore")
@inject("roomStore")
@inject("reserveStore")
@observer
export default class AdminReseveInfoPage extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      modalOpen: false
    };
    this.fetchRoom = this.fetchRoom.bind(this);
    this.handleModalAccept = this.handleModalAccept.bind(this);
    this.handleModalDecline = this.handleModalDecline.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }

  public async componentWillMount() {
    const params = this.props.match.params as IRoutingParamsWithGID;
    await this.props.authStore.verifyCredential();
    registrationMessagingClient();
    this.fetchRoom();
    this.props.reserveStore.fetchReservesByGID(params.slug, params.gid);
  }

  public fetchRoom() {
    const { roomStore } = this.props;
    const params = this.props.match.params as IRoutingParamsWithGID;
    roomStore.setRoom(params.slug);
  }

  public handleModalAccept() {
    const room = this.props.roomStore.room;
    const gid = this.props.reserveStore.reserves[0].gid;
    if (!room || !gid) {
      return;
    }
    this.props.reserveStore.deleteReserve(room.slug, gid);
    this.props.history.push(`/admin/rooms/${room.slug}`);
  }

  public handleModalDecline() {
    this.setState({
      modalOpen: false
    });
  }

  public onDeleteClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    this.setState({
      modalOpen: true
    });
  }

  public onBackButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    const room = this.props.roomStore.room;
    if (!room) {
      return;
    }
    this.props.history.push(`/admin/rooms/${room.slug}`);
  }

  public render() {
    if (this.props.authStore.loading) {
      return <LoadingPage />;
    }

    if (!this.props.authStore.isLoggedIn) {
      return <Redirect push={false} to="/login" />;
    }

    if (!this.props.roomStore.room) {
      return <LoadingPage />;
    }

    if (this.props.roomStore.error) {
      return <h1>会議室が見つかりませんでした。</h1>;
    }

    const reserves = this.props.reserveStore.reserves;
    const room = this.props.roomStore.room;

    if (!reserves.length) {
      return (
        <section data-test="admin-top">
          <AdminHeader authStore={this.props.authStore} />
          <div className={styles.inner}>
            <h1 className={styles.heading}>{room.name}</h1>
            <h2 className={styles.notice}>予約が見つかりませんでした。</h2>
          </div>
        </section>
      );
    }

    const formatDate = (date: Date) => {
      const momented = moment(date);
      return `${momented.year()}年${momented.month() +
        1}月${momented.date()}日 ${momented.hour()}時${momented.minute()}分`;
    };

    return (
      <section data-test="admin-top">
        <Modal
          title="確認"
          body="予約を削除します。よろしいですか？"
          isOpen={this.state.modalOpen}
          onAccept={this.handleModalAccept}
          onDecline={this.handleModalDecline}
        />
        <AdminHeader authStore={this.props.authStore} />
        <div className={styles.inner}>
          <h1 className={styles.heading}>予約確認</h1>
          <div className={styles.customerInfoWrapper}>
            <Label className={styles.label}>会議室名</Label>
            <p className={styles.text} data-test="roomName">
              {this.props.roomStore.room.name}
            </p>

            <Label className={styles.label}>お時間</Label>
            <p className={styles.text} data-test="time">
              {formatDate(reserves[0].startAt.toDate())}
            </p>

            <Label className={styles.label}>ご利用時間</Label>
            <p className={styles.text} data-test="hours">
              {reserves.length}時間
            </p>

            <Label className={styles.label}>氏名</Label>
            <p className={styles.text} data-test="customerName">
              {reserves[0].customerName} 様
            </p>

            <Label className={styles.label}>メールアドレス</Label>
            <p className={styles.text} data-test="customerEmail">
              {reserves[0].email}
            </p>

            <Label className={styles.label}>電話番号</Label>
            <p className={styles.text} data-test="customerTel">
              {reserves[0].tel}
            </p>

            <Label className={styles.label}>ご要望</Label>
            <p className={styles.text} data-test="demand">
              {reserves[0].demand}
            </p>
            <div className={styles.buttons}>
              <Button
                className={styles.backBtn}
                onClick={this.onBackButtonClick}
              >
                戻る
              </Button>
              <Button className={styles.deleteBtn} onClick={this.onDeleteClick}>
                削除
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
