import { action, computed, observable } from "mobx";
import { IRoom } from "../models/Room";
import firebase from "../vendor/firebase";

const db = firebase.firestore();
const settings = { timestampsInSnapshots: true };
db.settings(settings);

export interface IRoomStore {
  room: IRoom | null;
  rooms: IRoom[];
  error: Error | null;
  addRoom: (room: IRoom) => void;
  fetchRooms: () => void;
  setRoom: (slug: string) => void;
}

export default class RoomStore implements IRoomStore {
  @observable public room: IRoom | null = null;
  @observable public rooms: IRoom[] = [];
  @observable public error: Error | null = null;

  public addRoom(room: IRoom) {
    this.rooms.push(room);
  }

  @action.bound
  public async fetchRooms() {
    this.rooms = [];
    const roomsRef = db.collection("rooms");
    const querySnapshot = await roomsRef.get().catch(err => {
      this.error = err;
      return;
    });
    if (querySnapshot) {
      querySnapshot.docs.forEach(doc => this.addRoom(doc.data() as IRoom));
    }
  }

  @action.bound
  public async setRoom(slug: string) {
    this.room = null;
    const roomsRef = db.collection("rooms");
    const querySnapshot = await roomsRef.get().catch(err => {
      this.error = err;
      return;
    });
    if (querySnapshot) {
      const filtered = querySnapshot.docs.filter(doc => {
        const r = doc.data() as IRoom;
        return r.slug === slug;
      })[0];
      if (!filtered) {
        this.error = new Error("room not found");
        return;
      }
      const room = filtered.data() as IRoom;
      this.room = room;
    }
  }
}
