import { History } from "history";
import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import MediaQuery from "react-responsive";
import ReactRouter from "react-router";
import { Element, scroller } from "react-scroll";
import Header from "../../../components/molecules/Header";
import { IRoutingParams } from "../../../models/RoutingParams";
import { IReserveStore } from "../../../stores/Reserve"; // shadowingエラー対策
import { IRoomStore } from "../../../stores/Room";
import todayReserves from "../../../utils/todayReserves";
import { validateEmail, validateTel } from "../../../utils/validation";
import Button from "../../atoms/Button";
import Textarea from "../../atoms/Textarea";
import CustomerInfoForm from "../../molecules/CustomerInfoForm";
import UtilizationTimeSelector from "../../molecules/UtilizationTimeSelector";
import Calendar from "../../organisms/Calendar";
import LoadingPage from "../Loading";
import styles from "./styles.module.scss";

interface IProps {
  roomStore: IRoomStore;
  reserveStore: IReserveStore;
  match: ReactRouter.match;
  history: History;
}

interface ISelectedDatePanelProps {
  selectedDate: moment.Moment | null;
}

interface ICustomerInfoProps {
  customerName: string;
  email: string;
  tel: string;
}

@inject("roomStore")
@inject("reserveStore")
@observer
class RoomPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.handleCustomerNameChange = this.handleCustomerNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleTelChange = this.handleTelChange.bind(this);
    this.fetchRoom = this.fetchRoom.bind(this);
    this.handleHoursChange = this.handleHoursChange.bind(this);
    this.handleDemandChange = this.handleDemandChange.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
  }

  public componentWillMount() {
    this.fetchRoom();
    this.props.reserveStore.resetStore();
  }

  public handleScroll(key: string) {
    scroller.scrollTo(key, {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuad",
      offset: 50
    });
  }

  public componentWillUpdate(nextProps: IProps) {
    setTimeout(() => {
      this.handleScroll("hours");
    });
  }

  public fetchRoom() {
    const { roomStore, match } = this.props;
    const params = match.params as IRoutingParams;
    roomStore.setRoom(params.slug);
  }

  public handleCustomerNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.reserveStore.customerName = event.currentTarget.value;
  }

  public handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.reserveStore.email = event.currentTarget.value;
  }

  public handleTelChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.reserveStore.tel = event.currentTarget.value;
  }

  public handleHoursChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.props.reserveStore.hours = parseInt(event.currentTarget.value, 10);
  }

  public handleDemandChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.props.reserveStore.demand = event.currentTarget.value;
  }

  public handleConfirmClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    // いらないスラッシュを消す
    const url = this.props.match.url.replace(/\/$/, "");
    this.props.history.push(`${url}/confirm`);
  }

  public render() {
    const { roomStore, reserveStore } = this.props;

    const room = roomStore.room;

    if (!room) {
      return <LoadingPage />;
    }

    if (roomStore.error) {
      return <h1>会議室が見つかりませんでした。</h1>;
    }

    const SelectedDatePanel = (props: ISelectedDatePanelProps) => {
      if (props.selectedDate) {
        return (
          <Element name="hours" className={styles.inner__centering}>
            <h3 className={styles.heading}>何時間のご利用ですか？</h3>
            <UtilizationTimeSelector
              selectedHours={this.props.reserveStore.hours || 0}
              onChange={this.handleHoursChange}
              room={room}
              selectedDateTime={props.selectedDate}
              reserves={todayReserves(
                props.selectedDate,
                reserveStore.reserves
              )}
            />
          </Element>
        );
      }
      return null;
    };

    const isSubmittable = (
      customerName: string,
      email: string,
      tel: string
    ) => {
      return customerName !== "" && validateEmail(email) && validateTel(tel);
    };

    const SubmitPanel = (props: ICustomerInfoProps) => {
      if (isSubmittable(props.customerName, props.email, props.tel)) {
        return (
          <Button
            onClick={this.handleConfirmClick}
            className={styles.confirmButton}
          >
            確認画面へ
          </Button>
        );
      }
      return null;
    };

    return (
      <div className={styles.wrapper}>
        <Header room={roomStore.room} />
        <div className={styles.inner}>
          <h3 className={styles.heading}>開始時間を選択してください</h3>

          {/* mobile */}
          <MediaQuery query="(max-device-width: 1224px)">
            <Calendar
              roomStore={roomStore}
              reserveStore={reserveStore}
              max={4}
            />
          </MediaQuery>

          {/* PC */}
          <MediaQuery query="(min-device-width: 1224px)">
            <Calendar roomStore={roomStore} reserveStore={reserveStore} />
          </MediaQuery>

          <SelectedDatePanel
            selectedDate={this.props.reserveStore.selectedDate}
          />
          {this.props.reserveStore.hours ? (
            <div className={styles.inner__centering}>
              <h3 className={styles.heading}>お客様の情報を入力してください</h3>
              <CustomerInfoForm
                onCustomerNameChange={this.handleCustomerNameChange}
                onEmailChange={this.handleEmailChange}
                onTelChange={this.handleTelChange}
                customerName={this.props.reserveStore.customerName}
                email={this.props.reserveStore.email}
                tel={this.props.reserveStore.tel}
              />
            </div>
          ) : null}
          {isSubmittable(
            this.props.reserveStore.customerName,
            this.props.reserveStore.email,
            this.props.reserveStore.tel
          ) ? (
            <div className={styles.inner__centering}>
              <h3 className={styles.heading}>ご要望はございますか？（任意）</h3>
              <Textarea
                className={styles.textArea}
                placeholder="ご要望"
                onChange={this.handleDemandChange}
                value={this.props.reserveStore.demand}
              />
            </div>
          ) : null}
          <SubmitPanel
            customerName={this.props.reserveStore.customerName}
            email={this.props.reserveStore.email}
            tel={this.props.reserveStore.tel}
          />
        </div>
      </div>
    );
  }
}

export default RoomPage;
