import * as React from "react";
import ReactModal from "react-modal";
import Button from "../../atoms/Button";
import styles from "./styles.module.scss";

if (process.env.NODE_ENV !== "test") {
  ReactModal.setAppElement("#root");
}

interface IProps {
  isOpen: boolean;
  title: string;
  body: string;
  onAfterOpen?: () => void;
  onAccept: () => void;
  onDecline: () => void;
}

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0"
  }
};

export class Modal extends React.Component<IProps> {
  public render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        onAfterOpen={this.props.onAfterOpen}
        onRequestClose={this.props.onDecline}
        style={modalStyle}
      >
        <header className={styles.title}>{this.props.title}</header>
        <div className={styles.body}>{this.props.body}</div>
        <div className={styles.buttons}>
          <Button className={styles.danger} onClick={this.props.onAccept}>
            確定
          </Button>
          <Button onClick={this.props.onDecline}>キャンセル</Button>
        </div>
      </ReactModal>
    );
  }
}
