import * as React from "react";
import { Link } from "react-router-dom";
import { IRoom } from "../../../models/Room";
import styles from "./styles.module.scss";

interface IProps {
  room?: IRoom | null;
}

const Header = (props: IProps) => (
  <header className={styles.header}>
    <div data-test="feed" className={styles.feed}>
      <h1 className={styles.feed__heading}>
        <Link className={styles.feed__text} to="/">
          ブレインファーム会議室予約システム
        </Link>
      </h1>
    </div>
    <div data-test="title" className={styles.header__inner}>
      <div className={styles.header__left}>
        <h2 className={styles.header__title}>
          {props.room ? props.room.name : "ブレインファーム会議室予約システム"}
        </h2>
      </div>
    </div>
  </header>
);

export default Header;
