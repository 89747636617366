import { Provider } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Router from "./Router";
import * as serviceWorker from "./serviceWorker";
import AuthStore from "./stores/Auth";
import ReserveStore from "./stores/Reserve";
import RoomStore from "./stores/Room";

const stores = {
  reserveStore: new ReserveStore(),
  roomStore: new RoomStore(),
  authStore: new AuthStore()
};

ReactDOM.render(
  <Provider {...stores}>
    <Router roomStore={stores.roomStore} />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
