import moment from "moment";
import { IReserve } from "../models/Reserve";

const todayReserves = (today: moment.Moment, reserves: IReserve[]) =>
  reserves.filter(
    r =>
      r.startAt.toDate().getFullYear() === today.year() &&
      r.startAt.toDate().getMonth() === today.month() &&
      r.startAt.toDate().getDate() === today.date()
  );

export default todayReserves;
