import axios from "axios";
import moment from "moment";
import { IReserve } from "../models/Reserve";
import firebase from "../vendor/firebase";

export const sendEmail = async (reserve: IReserve, hours: number) => {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    return;
  }
  await firebase
    .auth()
    .signInAnonymously()
    .catch(err => {
      // tslint:disable-next-line:no-console
      console.error(err);
    });
  currentUser.getIdToken().then(token => {
    const headers = { Authorization: `Bearer ${token}` };
    const date = reserve.startAt.toDate();
    const dateMoment = moment(date);
    const dateStr = `${dateMoment.year()}年${dateMoment.month()}月${dateMoment.date()}日 ${dateMoment.hours()}時`;
    const msgBody = `BrainFarm予約システムに新しい予約が入りました。
    
    お客様氏名: ${reserve.customerName}
    ご希望日時:${dateStr}から${hours}時間
    ご要望: ${reserve.demand}
    メールアドレス: ${reserve.email}
    お電話番号: ${reserve.tel}`;
    const body = {
      // to: "app@brain-f.com",
      to: "info@brain-f.com",
      from: "reserve@brain-f.com",
      subject: "[BrainFarm予約システム] 新しい予約",
      body: msgBody
    };
    axios.post(
      "https://us-central1-reserve-4aaca.cloudfunctions.net/sendgridEmail",
      body,
      {
        headers
      }
    );
  });
};
