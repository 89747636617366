import { History } from "history";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Redirect } from "react-router";
import { IAuthStore } from "../../../../stores/Auth";
import Button from "../../../atoms/Button";
import TextInput from "../../../atoms/TextInput";
import LoadingPage from "../../Loading";
import styles from "./styles.module.scss";

interface IProps {
  history: History;
  authStore: IAuthStore;
}

@inject("authStore")
@observer
export default class LoginPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePasswordKeyPress = this.handlePasswordKeyPress.bind(this);
  }

  public async componentWillMount() {
    this.props.authStore.clearForm();
    this.props.authStore.verifyCredential();
  }

  public handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.authStore.email = event.target.value;
  }

  public handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.authStore.password = event.target.value;
  }

  public signIn() {
    this.props.authStore
      .signIn()
      .then(() => {
        this.props.history.replace("/admin");
      })
      .catch(() => {
        this.props.authStore.password = "";
      });
  }

  public handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    this.signIn();
  }

  public handlePasswordKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      this.signIn();
    }
  }

  public render() {
    if (this.props.authStore.loading) {
      return <LoadingPage />;
    }

    if (this.props.authStore.isLoggedIn) {
      return <Redirect push={false} to="/admin" />;
    }

    return (
      <section className={styles.wrapper} data-test="admin-login">
        <div className={styles.inner}>
          <h1 className={styles.inner__title}>BrainFarm会議室予約システム</h1>
          <TextInput
            className={styles.inner__input}
            onChange={this.handleEmailChange}
            value={this.props.authStore.email}
            placeholder="メールアドレス"
            type="email"
            data-test="email"
          />
          <TextInput
            className={styles.inner__input}
            onChange={this.handlePasswordChange}
            onKeyPress={this.handlePasswordKeyPress}
            value={this.props.authStore.password}
            placeholder="パスワード"
            type="password"
            data-test="password"
          />
          <Button onClick={this.handleSubmit} type="submit" data-test="submit">
            ログイン
          </Button>
          {this.props.authStore.error ? (
            <p className={styles.error} data-test="error">
              {this.props.authStore.error.message}
            </p>
          ) : null}
        </div>
      </section>
    );
  }
}
