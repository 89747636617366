import * as React from "react";
import styles from "./styles.module.scss";

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Label = (props: IProps) => (
  <label className={[styles.label, props.className].join(" ")}>
    {props.children}
  </label>
);

export default Label;
