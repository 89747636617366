import { action, computed, observable } from "mobx";
import { IUser } from "../models/User";
import firebase from "../vendor/firebase";

const db = firebase.firestore();
const settings = { timestampsInSnapshots: true };
db.settings(settings);

export interface IAuthStore {
  email: string;
  password: string;
  uid: string | null;
  displayName: string | null;
  error: Error | null;
  isLoggedIn: boolean;
  loading: boolean;

  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  fetchUser: (uid: string) => Promise<IUser | null>;
  createInitialUser: (uid: string) => Promise<IUser>;
  setUser: (user: IUser) => void;
  updateDisplayName: (uid: string, displayName: string) => Promise<void>;
  verifyCredential: () => Promise<void>;
  clearUser: () => void;
  clearForm: () => void;
}

export default class AuthStore implements IAuthStore {
  @observable public email: string = "";
  @observable public password: string = "";
  @observable public uid: string | null = null;
  @observable public displayName: string | null = null;
  @observable public error: Error | null = null;
  @observable public loading: boolean = false;

  @computed get isLoggedIn() {
    return !!this.uid;
  }

  @action.bound
  public async createInitialUser(uid: string) {
    const ref = db.collection("users").doc(uid);
    const skel: IUser = {
      uid,
      displayName: "名称未設定"
    };
    ref.set(skel).catch(err => {
      this.error = err;
      return;
    });
    return skel;
  }

  @action.bound
  public async fetchUser(uid: string) {
    const ref = db.collection("users").doc(uid);
    const doc = await ref.get().catch(err => {
      this.error = err;
      return null;
    });
    if (doc) {
      return doc.data() as IUser;
    }
    return null;
  }

  @action.bound
  public async signIn() {
    this.loading = true;
    const res = await firebase
      .auth()
      .signInWithEmailAndPassword(this.email, this.password)
      .catch(err => {
        this.error = err;
      });
    if (res && res.user) {
      const storedUser = await this.fetchUser(res.user.uid);
      if (!storedUser) {
        const user = await this.createInitialUser(res.user.uid);
        if (user) {
          this.setUser(user);
          this.loading = false;
          return Promise.resolve();
        }
        this.loading = false;
        return Promise.reject();
      }
      this.setUser(storedUser);
      this.loading = false;
      return Promise.resolve();
    }
    this.loading = false;
    return Promise.reject();
  }

  @action.bound
  public async updateDisplayName(uid: string, displayName: string) {
    const ref = db.collection("users").doc(uid);
    await ref.update({ displayName }).catch(err => {
      this.error = err;
      return;
    });
    this.displayName = displayName;
  }

  @action.bound
  public setUser(user: IUser) {
    this.uid = user.uid;
    this.displayName = user.displayName;
  }

  @action.bound
  public async signOut() {
    await firebase.auth().signOut();
    this.clearUser();
  }

  @action.bound
  public async verifyCredential() {
    this.loading = true;
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const storedUser = await this.fetchUser(user.uid);
        if (storedUser) {
          this.setUser(storedUser);
        }
      }
      this.loading = false;
    });
  }

  @action.bound
  public clearUser() {
    this.uid = "";
    this.displayName = null;
  }

  @action.bound
  public clearForm() {
    this.error = null;
    this.email = "";
    this.password = "";
  }
}
