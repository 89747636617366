import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.scss";

interface IProps {
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  className?: string;
  required?: boolean;
  value?: string;
}

const Textarea = (props: IProps) => {
  const classes = classnames(styles.textArea, props.className);
  return (
    <textarea
      data-test="textarea"
      className={classes}
      onChange={props.onChange}
      placeholder={props.placeholder}
      required={props.required}
      value={props.value}
    />
  );
};

Textarea.defaultProps = {
  classNames: styles.textInput,
  placeholder: "",
  required: false,
  value: ""
};

export default Textarea;
