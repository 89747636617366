import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.scss";

interface IProps {
  className?: string;
  disabled?: boolean;
  children: React.ReactNode;
  type?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button = (props: IProps) => {
  const classes = props.disabled
    ? classnames(styles.button, styles.button__disabled, props.className)
    : classnames(styles.button, props.className);
  return (
    <button
      className={classes}
      type={props.type}
      disabled={props.disabled}
      onClick={props.disabled ? undefined : props.onClick}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  type: "button",
  className: ""
};

export default Button;
