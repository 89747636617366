import { action, observable } from "mobx";
import moment from "moment";
import { IReserve } from "../models/Reserve";
import firebase from "../vendor/firebase";

const db = firebase.firestore();
const settings = { timestampsInSnapshots: true };
db.settings(settings);

export interface IReserveStore {
  hours: number | null;
  selectedDate: moment.Moment | null;
  error: Error | null;
  customerName: string;
  email: string;
  tel: string;
  demand: string;
  reserves: IReserve[];
  addReserve: (reserve: IReserve) => void;
  fetchReserves: (roomSlug: string) => Promise<void>;
  fetchReservesByGID: (roomSlug: string, gid: string) => Promise<void>;
  createReserve: (roomSlug: string, reserve: IReserve) => Promise<void>;
  resetStore: () => void;
  deleteReserve: (roomSlug: string, gid: string) => Promise<void>;
}

export default class ReserveStore implements IReserveStore {
  @observable public hours: number | null = null;
  @observable public selectedDate: moment.Moment | null = null;
  @observable public error: Error | null = null;
  @observable public customerName: string = "";
  @observable public email: string = "";
  @observable public tel: string = "";
  @observable public demand: string = "";
  @observable public reserves: IReserve[] = [];

  @action.bound
  public addReserve(room: IReserve) {
    this.reserves.push(room);
  }

  @action.bound
  public async fetchReserves(roomSlug: string) {
    this.reserves = [];
    const reservesRef = db
      .collection("rooms")
      .doc(roomSlug)
      .collection("reserves");
    const querySnapshot = await reservesRef.get().catch(err => {
      this.error = err;
      return;
    });
    if (querySnapshot) {
      querySnapshot.docs.forEach(doc => {
        const res = doc.data() as IReserve;
        if (res.room === roomSlug) {
          this.addReserve(res);
        }
      });
    }
  }

  @action.bound
  public async fetchReservesByGID(roomSlug: string, gid: string) {
    this.reserves = [];
    const reservesRef = db
      .collection("rooms")
      .doc(roomSlug)
      .collection("reserves")
      .where("gid", "==", gid);
    const querySnapshot = await reservesRef.get().catch(err => {
      this.error = err;
      return;
    });
    if (querySnapshot) {
      querySnapshot.docs.forEach(doc => {
        const res = doc.data() as IReserve;
        if (res.room === roomSlug) {
          this.addReserve(res);
        }
      });
    }
  }

  @action.bound
  public createReserve(roomSlug: string, reserve: IReserve) {
    const reservesRef = db
      .collection("rooms")
      .doc(roomSlug)
      .collection("reserves");
    return reservesRef
      .add(reserve)
      .then(() => {
        this.addReserve(reserve);
      })
      .catch(err => {
        this.error = err;
      });
  }

  @action.bound
  public resetStore() {
    this.selectedDate = null;
    this.tel = "";
    this.email = "";
    this.customerName = "";
    this.hours = null;
    this.demand = "";
  }

  @action.bound
  public async deleteReserve(roomSlug: string, gid: string) {
    const ref = db
      .collection("rooms")
      .doc(roomSlug)
      .collection("reserves")
      .where("gid", "==", gid);
    const querySnapshot = await ref.get();
    querySnapshot.forEach(doc => doc.ref.delete());
    this.reserves = this.reserves.filter(r => r.gid !== gid);
  }
}
