import { History } from "history";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { match } from "react-router";
import { IReserveStore } from "../../../stores/Reserve";
import { IRoomStore } from "../../../stores/Room";
import Button from "../../atoms/Button";
import Label from "../../atoms/Label";
import Header from "../../molecules/Header";
import styles from "./styles.module.scss";

interface IProps {
  reserveStore: IReserveStore;
  roomStore: IRoomStore;
  match: match;
  history: History;
}

interface IState {
  modalOpen: boolean;
}

@inject("reserveStore")
@inject("roomStore")
@observer
export default class ThanksPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      modalOpen: false
    };
    this.handleToHomepage = this.handleToHomepage.bind(this);
  }

  public handleToHomepage() {
    const room = this.props.roomStore.room;
    if (room) {
      location.href = room.homepage;
    }
  }

  public render() {
    if (!this.props.roomStore.room || !this.props.reserveStore.selectedDate) {
      return (
        <h1 data-test="directError">このページには直接アクセスできません。</h1>
      );
    }
    const date = this.props.reserveStore.selectedDate;
    const timeText = `${date.year()}年${date.month() +
      1}月${date.date()}日 ${date.hour()}時から`;
    return (
      <div className={styles.wrapper}>
        <Header room={this.props.roomStore.room} />
        <div className={styles.inner}>
          <h3 className={styles.heading}>ご予約ありがとうございました。</h3>

          <Label className={styles.label}>会議室名</Label>
          <p className={styles.text} data-test="roomName">
            {this.props.roomStore.room.name}
          </p>

          <Label className={styles.label}>お時間</Label>
          <p className={styles.text} data-test="time">
            {timeText}
          </p>

          <Label className={styles.label}>ご利用時間</Label>
          <p className={styles.text} data-test="hours">
            {this.props.reserveStore.hours}時間
          </p>

          <Label className={styles.label}>氏名</Label>
          <p className={styles.text} data-test="customerName">
            {this.props.reserveStore.customerName} 様
          </p>
          <Button
            className={styles.toHomepageBtn}
            onClick={this.handleToHomepage}
          >
            ホームページへ
          </Button>
        </div>
      </div>
    );
  }
}
