import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { IReserveStore } from "../../../stores/Reserve";
import { IRoomStore } from "../../../stores/Room";
import todayReserves from "../../../utils/todayReserves";
import Button from "../../atoms/Button";
import CalendarDay from "../../molecules/CalendarDay";
import styles from "./styles.module.scss";

interface IProps {
  reserveStore: IReserveStore;
  roomStore: IRoomStore;
  max?: number;
}

interface IState {
  cursor: number;
}

@inject("reserveStore")
@inject("roomStore")
@observer
export default class Calendar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      cursor: 0
    };
    this.handleClick = this.handleClick.bind(this);
    this.decrementCursor = this.decrementCursor.bind(this);
    this.incrementCursor = this.incrementCursor.bind(this);
    this.resetCursor = this.resetCursor.bind(this);
  }

  public componentWillMount() {
    const room = this.props.roomStore.room;
    if (!room) {
      return;
    }
    this.props.reserveStore.fetchReserves(room.slug);
  }

  public handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.props.reserveStore.selectedDate = moment(event.currentTarget.value);
  }

  public decrementCursor() {
    const n = this.props.max ? this.props.max : 7;
    this.setState({
      cursor: this.state.cursor - n
    });
  }

  public incrementCursor() {
    const n = this.props.max ? this.props.max : 7;
    this.setState({
      cursor: this.state.cursor + n
    });
  }

  public resetCursor() {
    this.setState({
      cursor: 0
    });
  }

  public render() {
    // tslint:disable-next-line:prefer-array-literal
    const arr = Array.from(Array(this.props.max ? this.props.max : 7).keys());

    const room = this.props.roomStore.room;
    const reserves = this.props.reserveStore.reserves;
    if (!room) {
      return <React.Fragment />;
    }

    return (
      <React.Fragment>
        <div className={styles.cursors}>
          <Button
            onClick={this.decrementCursor}
            className={styles.cursors__cursor}
          >
            前へ
          </Button>
          <Button onClick={this.resetCursor} className={styles.cursors__cursor}>
            今日
          </Button>
          <Button
            onClick={this.incrementCursor}
            className={styles.cursors__cursor}
          >
            次へ
          </Button>
        </div>
        <div className={styles.calendarDayWrapper}>
          {arr.map(i => (
            <CalendarDay
              key={this.state.cursor + i}
              date={moment()
                .add(this.state.cursor + i, "days")
                .hour(0)
                .minute(0)
                .second(0)
                .millisecond(0)}
              room={room}
              reserves={todayReserves(
                moment().add(this.state.cursor + i, "days"),
                reserves
              )}
              onClick={this.handleClick}
              selected={this.props.reserveStore.selectedDate}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }
}
