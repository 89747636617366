import * as React from "react";
import TextInput from "../../atoms/TextInput";
import styles from "./styles.module.scss";

interface IProps {
  onCustomerNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTelChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  customerName: string;
  email: string;
  tel: string;
}

const CustomerInfoForm = (props: IProps) => {
  return (
    <React.Fragment>
      <TextInput
        className={styles.textInput}
        data-test="customer-name"
        onChange={props.onCustomerNameChange}
        placeholder="お名前"
        value={props.customerName}
      />
      <TextInput
        className={styles.textInput}
        data-test="email"
        type="email"
        onChange={props.onEmailChange}
        placeholder="メールアドレス"
        value={props.email}
      />
      <TextInput
        className={styles.textInput}
        data-test="tel"
        type="tel"
        onChange={props.onTelChange}
        placeholder="電話番号"
        value={props.tel}
      />
    </React.Fragment>
  );
};

export default CustomerInfoForm;
