import classnames from "classnames";
import moment from "moment";
import * as React from "react";
import styles from "./styles.module.scss";

interface IProps {
  reserved: boolean;
  selected: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  value: string | string[] | number;
}

const disabledClasses = classnames(
  styles.calendarCell,
  styles.calendarCell__disabled
);

const selectedClasses = classnames(
  styles.calendarCell,
  styles.calendarCell__selected
);

const CalendarCell = (props: IProps) => {
  if (props.selected) {
    return (
      <button className={selectedClasses} disabled={true} value={props.value}>
        {moment(props.value).hours()}:00
      </button>
    );
  }
  if (props.reserved) {
    return (
      <button className={disabledClasses} disabled={true} value={props.value}>
        {moment(props.value).hours()}:00
      </button>
    );
  }
  return (
    <button
      className={styles.calendarCell}
      onClick={props.onClick}
      value={props.value}
    >
      {moment(props.value).hours()}:00
    </button>
  );
};

export default CalendarCell;
