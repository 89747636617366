import { History } from "history";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Redirect } from "react-router";
import { IAuthStore } from "../../../../stores/Auth";
import { IRoomStore } from "../../../../stores/Room";
import { registrationMessagingClient } from "../../../../vendor/firebase";
import Button from "../../../atoms/Button";
import Label from "../../../atoms/Label";
import TextInput from "../../../atoms/TextInput";
import AdminHeader from "../../../molecules/AdminHeader";
import LoadingPage from "../../Loading";
import styles from "./styles.module.scss";

interface IProps {
  history: History;
  authStore: IAuthStore;
  roomStore: IRoomStore;
}

interface IState {
  displayName: string;
}

@inject("authStore")
@inject("roomStore")
@observer
export default class AdminPrefsPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      displayName: ""
    };
    this.backToTop = this.backToTop.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handleUpdateDisplayNameClick = this.handleUpdateDisplayNameClick.bind(
      this
    );
  }

  public async componentWillMount() {
    await this.props.authStore.verifyCredential();
    registrationMessagingClient();
    const displayName = this.props.authStore.displayName;
    if (!displayName) {
      return;
    }
    this.setState({
      displayName
    });
  }

  public backToTop(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    this.props.history.push("/admin");
  }

  public handleUserNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      displayName: event.currentTarget.value
    });
  }

  public handleUpdateDisplayNameClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    const uid = this.props.authStore.uid;
    if (!uid) {
      return;
    }
    const displayName = this.state.displayName;
    this.props.authStore.updateDisplayName(uid, displayName);
  }

  public render() {
    if (this.props.authStore.loading) {
      return <LoadingPage />;
    }

    if (!this.props.authStore.isLoggedIn) {
      return <Redirect push={false} to="/login" />;
    }

    return (
      <section data-test="admin-top">
        <AdminHeader authStore={this.props.authStore} />
        <div className={styles.content}>
          <div>
            <Label>ユーザー名</Label>
            <TextInput
              className={styles.input}
              onChange={this.handleUserNameChange}
              value={this.state.displayName}
              placeholder={this.state.displayName}
            />
            <Button
              className={styles.button}
              onClick={this.handleUpdateDisplayNameClick}
            >
              確定
            </Button>
          </div>
          <Button onClick={this.backToTop}>戻る</Button>
        </div>
      </section>
    );
  }
}
