import { History } from "history";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { IRoom } from "../../../../models/Room";
import { IAuthStore } from "../../../../stores/Auth";
import { IRoomStore } from "../../../../stores/Room";
import { registrationMessagingClient } from "../../../../vendor/firebase";
import AdminHeader from "../../../molecules/AdminHeader";
import LoadingPage from "../../Loading";
import styles from "./styles.module.scss";

interface IProps {
  history: History;
  authStore: IAuthStore;
  roomStore: IRoomStore;
}
interface IRoomItemProps {
  room: IRoom;
}

@inject("authStore")
@inject("roomStore")
@observer
export default class AdminTopPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public async componentWillMount() {
    await this.props.authStore.verifyCredential();
    registrationMessagingClient();
  }

  public render() {
    if (this.props.authStore.loading) {
      return <LoadingPage />;
    }

    if (!this.props.authStore.isLoggedIn) {
      return <Redirect push={false} to="/login" />;
    }

    const RoomItem = (props: IRoomItemProps) => (
      <li>
        <Link to={`/admin/rooms/${props.room.slug}`}>{props.room.name}</Link>
      </li>
    );

    return (
      <section data-test="admin-top">
        <AdminHeader authStore={this.props.authStore} />
        <div className={styles.content}>
          <h2 className={styles.heading}>会議室をお選びください</h2>
          <ul>
            {this.props.roomStore.rooms.map(room => (
              <RoomItem key={room.slug} room={room} />
            ))}
          </ul>
          <h2 className={styles.heading}>ユーザー設定</h2>
          <Link to="/admin/prefs">アカウント設定</Link>
        </div>
      </section>
    );
  }
}
