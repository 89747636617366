import { History } from "history";
import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { match } from "react-router";
import { IReserve } from "../../../models/Reserve";
import { IRoom } from "../../../models/Room";
import { IRoutingParams } from "../../../models/RoutingParams";
import { IReserveStore } from "../../../stores/Reserve";
import { IRoomStore } from "../../../stores/Room";
import { sendEmail } from "../../../utils/mail";
import firebase, {
  sendReserveNotifyToAdminTopics
} from "../../../vendor/firebase";
import Button from "../../atoms/Button";
import Label from "../../atoms/Label";
import Header from "../../molecules/Header";
import { Modal } from "../../organisms/Modal";
import styles from "./styles.module.scss";

interface IProps {
  reserveStore: IReserveStore;
  roomStore: IRoomStore;
  match: match;
  history: History;
}

interface IState {
  modalOpen: boolean;
}

@inject("reserveStore")
@inject("roomStore")
@observer
export default class ConfirmPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      modalOpen: false
    };
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleCompleteCreateReserve = this.handleCompleteCreateReserve.bind(
      this
    );
    this.handleModalAccept = this.handleModalAccept.bind(this);
    this.handleModalDecline = this.handleModalDecline.bind(this);
  }

  public createGid(room: IRoom): string | undefined {
    return `${room.slug}_${moment().unix()}`;
  }

  public handleConfirmClick() {
    this.setState({
      modalOpen: true
    });
  }

  public handleBackClick() {
    const room = this.props.roomStore.room;
    if (!room) {
      return;
    }
    this.props.history.replace(`/rooms/${room.slug}`);
  }

  public handleModalAccept() {
    const room = this.props.roomStore.room;
    if (!room) {
      return;
    }
    const gid = this.createGid(room);
    if (!gid) {
      return;
    }
    const startAt = this.props.reserveStore.selectedDate;
    const customerName = this.props.reserveStore.customerName;
    const email = this.props.reserveStore.email;
    const tel = this.props.reserveStore.tel;
    const demand = this.props.reserveStore.demand;
    const hours = this.props.reserveStore.hours;
    if (!startAt) {
      return;
    }
    const newReserve: IReserve = {
      gid,
      customerName,
      email,
      tel,
      demand,
      room: room.slug,
      startAt: new firebase.firestore.Timestamp(startAt.unix(), 0)
    };
    // tslint:disable-next-line:prefer-array-literal
    const arr = Array.from(new Array(hours).keys());

    let notifiySent = false;

    arr.forEach(async i => {
      const tmp = Object.assign({}, newReserve);
      const momentedStartAt = moment(tmp.startAt.toDate());
      const modifiedStartAt = momentedStartAt.add(i, "hours");
      tmp.startAt = new firebase.firestore.Timestamp(modifiedStartAt.unix(), 0);
      await this.props.reserveStore
        .createReserve(room.slug, tmp)
        .catch(err => alert(err.message));
      if (!notifiySent) {
        sendReserveNotifyToAdminTopics(tmp);
        if (!hours) {
          notifiySent = true;
          return;
        }
        sendEmail(tmp, hours);
        notifiySent = true;
      }
    });
    this.handleCompleteCreateReserve();
  }

  public handleCompleteCreateReserve() {
    const params: IRoutingParams = this.props.match.params as IRoutingParams;
    this.props.history.replace(`/rooms/${params.slug}/thanks`);
  }

  public handleModalDecline() {
    this.setState({
      modalOpen: false
    });
  }

  public render() {
    if (!this.props.roomStore.room || !this.props.reserveStore.selectedDate) {
      return (
        <h1 data-test="directError">このページには直接アクセスできません。</h1>
      );
    }
    const date = this.props.reserveStore.selectedDate;
    const timeText = `${date.year()}年${date.month() +
      1}月${date.date()}日 ${date.hour()}時から`;
    return (
      <div className={styles.wrapper}>
        <Modal
          title="確認"
          body="予約を確定します。よろしいですか？"
          isOpen={this.state.modalOpen}
          onAccept={this.handleModalAccept}
          onDecline={this.handleModalDecline}
        />
        <Header room={this.props.roomStore.room} />
        <div className={styles.inner}>
          <h3 className={styles.heading}>ご入力内容をご確認ください</h3>

          <Label className={styles.label}>会議室名</Label>
          <p className={styles.text} data-test="roomName">
            {this.props.roomStore.room.name}
          </p>

          <Label className={styles.label}>お時間</Label>
          <p className={styles.text} data-test="time">
            {timeText}
          </p>

          <Label className={styles.label}>ご利用時間</Label>
          <p className={styles.text} data-test="hours">
            {this.props.reserveStore.hours}時間
          </p>

          <Label className={styles.label}>氏名</Label>
          <p className={styles.text} data-test="customerName">
            {this.props.reserveStore.customerName} 様
          </p>

          <Label className={styles.label}>メールアドレス</Label>
          <p className={styles.text} data-test="customerEmail">
            {this.props.reserveStore.email}
          </p>

          <Label className={styles.label}>電話番号</Label>
          <p className={styles.text} data-test="customerTel">
            {this.props.reserveStore.tel}
          </p>

          <Label className={styles.label}>ご要望</Label>
          <p className={styles.text} data-test="demand">
            {this.props.reserveStore.demand}
          </p>
          <Button className={styles.btn} onClick={this.handleBackClick}>
            戻る
          </Button>
          <Button className={styles.btn} onClick={this.handleConfirmClick}>
            予約確定
          </Button>
        </div>
      </div>
    );
  }
}
