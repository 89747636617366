import * as React from "react";
import Header from "../../molecules/Header";
import styles from "./styles.module.scss";

// TODO: 実装
const NoMatchPage = () => (
  <React.Fragment>
    <Header />
    <div className={styles.content}>
      <h2>お探しのページは見つかりませんでした。</h2>
    </div>
  </React.Fragment>
);

export default NoMatchPage;
