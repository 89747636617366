import { History } from "history";
import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { match, Redirect } from "react-router";
import { IMergedReserve } from "../../../../models/Reserve";
import { IRoutingParams } from "../../../../models/RoutingParams";
import { IAuthStore } from "../../../../stores/Auth";
import { IReserveStore } from "../../../../stores/Reserve";
import { IRoomStore } from "../../../../stores/Room";
import { registrationMessagingClient } from "../../../../vendor/firebase";
import Button from "../../../atoms/Button";
import AdminHeader from "../../../molecules/AdminHeader";
import { Modal } from "../../../organisms/Modal";
import LoadingPage from "../../Loading";
import styles from "./styles.module.scss";

interface IProps {
  history: History;
  authStore: IAuthStore;
  roomStore: IRoomStore;
  reserveStore: IReserveStore;
  match: match;
}

interface IState {
  modalOpen: boolean;
  selectedGid: string | null;
}

@inject("authStore")
@inject("roomStore")
@inject("reserveStore")
@observer
export default class AdminRoomPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      modalOpen: false,
      selectedGid: null
    };
    this.fetchRoom = this.fetchRoom.bind(this);
    this.handleModalAccept = this.handleModalAccept.bind(this);
    this.handleModalDecline = this.handleModalDecline.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.infoButtonClick = this.infoButtonClick.bind(this);
    this.backToTop = this.backToTop.bind(this);
  }

  public async componentWillMount() {
    const params = this.props.match.params as IRoutingParams;
    await this.props.authStore.verifyCredential();
    registrationMessagingClient();
    this.fetchRoom();
    this.props.reserveStore.fetchReserves(params.slug);
  }

  public fetchRoom() {
    const { roomStore } = this.props;
    const params = this.props.match.params as IRoutingParams;
    roomStore.setRoom(params.slug);
  }

  public handleModalAccept() {
    const room = this.props.roomStore.room;
    const gid = this.state.selectedGid;
    if (!room || !gid) {
      return;
    }
    this.props.reserveStore.deleteReserve(room.slug, gid);
    this.setState({
      modalOpen: false,
      selectedGid: null
    });
  }

  public handleModalDecline() {
    this.setState({
      modalOpen: false,
      selectedGid: null
    });
  }

  public onDeleteClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    this.setState({
      modalOpen: true,
      selectedGid: event.currentTarget.value
    });
  }

  public backToTop() {
    this.props.history.push("/admin");
  }

  public infoButtonClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    const room = this.props.roomStore.room;
    if (!room) {
      return;
    }
    const gid = event.currentTarget.value;
    this.props.history.push(`/admin/rooms/${room.slug}/${gid}`);
  }

  public render() {
    if (this.props.authStore.loading) {
      return <LoadingPage />;
    }

    if (!this.props.authStore.isLoggedIn) {
      return <Redirect push={false} to="/login" />;
    }

    if (!this.props.roomStore.room) {
      return <LoadingPage />;
    }

    if (this.props.roomStore.error) {
      return <h1>会議室が見つかりませんでした。</h1>;
    }

    const reserves = this.props.reserveStore.reserves;
    const room = this.props.roomStore.room;

    if (!reserves.length) {
      return (
        <section data-test="admin-top">
          <AdminHeader authStore={this.props.authStore} />
          <div className={styles.inner}>
            <h1 className={styles.heading}>{room.name}</h1>
            <h2 className={styles.notice}>予約が１件もありません。</h2>
          </div>
        </section>
      );
    }

    const collected = reserves.map((r, i, self) => {
      // 一つ前の予約のグループIDが同じなら処理を飛ばす
      if (self[i - 1] && self[i - 1].gid === r.gid) {
        return;
      }
      // 予約グループの最初の要素ならまとめて配列にする
      return self.filter(ir => ir.gid === r.gid);
    });

    // 重複排除
    const merged: IMergedReserve[] = [];
    collected.forEach((r, i) => {
      if (!r) {
        return;
      }
      const exists = merged.map(ir => ir.gid).indexOf(r[0].gid);
      if (exists !== -1) {
        return;
      }
      r.sort((a, b) => a.startAt.seconds - b.startAt.seconds);
      merged[merged.length + 1] = { ...r[0], hours: r.length };
    });

    const formatDate = (date: Date) => {
      const momented = moment(date);
      return `${momented.year()}年${momented.month() +
        1}月${momented.date()}日 ${momented.hour()}時${momented.minute()}分`;
    };

    return (
      <section data-test="admin-top">
        <Modal
          title="確認"
          body="予約を削除します。よろしいですか？"
          isOpen={this.state.modalOpen}
          onAccept={this.handleModalAccept}
          onDecline={this.handleModalDecline}
        />
        <AdminHeader authStore={this.props.authStore} />
        <div className={styles.inner}>
          <h1 className={styles.heading}>{room.name}</h1>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.table__th}>利用開始時刻</th>
                <th className={styles.table__th}>氏名</th>
                <th className={styles.table__th}>利用時間</th>
                <th className={styles.table__th}>操作</th>
              </tr>
            </thead>
            <tbody>
              {merged.map(r => (
                <tr
                  className={styles.table__tr}
                  key={r.startAt.toDate().toString()}
                >
                  <td className={styles.table__td}>
                    {formatDate(r.startAt.toDate())}開始
                  </td>
                  <td className={styles.table__td}>{r.customerName} 様</td>
                  <td className={styles.table__td}>{r.hours}時間</td>
                  <td className={styles.table__td}>
                    <button
                      onClick={this.infoButtonClick}
                      value={r.gid}
                      className={styles.opButton}
                    >
                      詳細
                    </button>
                    <button
                      onClick={this.onDeleteClick}
                      value={r.gid}
                      className={[styles.opButton, styles.opButtonDanger].join(
                        " "
                      )}
                    >
                      削除
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button className={styles.button} onClick={this.backToTop}>
            戻る
          </Button>
        </div>
      </section>
    );
  }
}
