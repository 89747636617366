import firebase, { messaging } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/storage";
import { IReserve } from "../models/Reserve";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIR_API_KEY,
  authDomain: process.env.REACT_APP_FIR_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIR_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIR_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIR_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIR_STORAGE_BUCKET
});

const serverKey = process.env.REACT_APP_FIR_MESSAGING_SERVER_KEY
  ? process.env.REACT_APP_FIR_MESSAGING_SERVER_KEY
  : "";

let msg: messaging.Messaging;

const setMsg = () => {
  if (messaging.isSupported() && !msg) {
    msg = firebase.messaging();
    msg.usePublicVapidKey(
      process.env.REACT_APP_FIR_PUBLIC_VAPID_KEY
        ? process.env.REACT_APP_FIR_PUBLIC_VAPID_KEY
        : ""
    );
  }
};

export const registrationMessagingClient = () => {
  setMsg();
  if (!msg) {
    return;
  }
  msg.requestPermission().then(() => {
    msg.getToken().then(token => {
      const url = `https://iid.googleapis.com/iid/v1/${token}/rel/topics/admins`;
      const method = "POST";
      const headers = {
        Authorization: `key=${serverKey}`,
        "Content-Type": "application/json"
      };
      fetch(url, { method, headers }).catch(alert);
    });
  });
};

export const sendReserveNotifyToAdminTopics = (reserve: IReserve) => {
  const url = "https://fcm.googleapis.com/fcm/send";
  const method = "POST";
  const headers = {
    Authorization: `key=${serverKey}`,
    "Content-Type": "application/json"
  };

  const startAt = reserve.startAt.toDate();
  const formattedStartAt = `${startAt.getFullYear()}年${startAt.getMonth() +
    1}月${startAt.getDate()}日 ${startAt.getHours()}時`;

  const bodyJson = {
    to: "/topics/admins",
    priority: "high",
    notification: {
      body: `${reserve.customerName}様 ${formattedStartAt}から予約`,
      title: "新しい予約"
    }
  };
  const body = JSON.stringify(bodyJson);
  // tslint:disable-next-line:no-console
  fetch(url, { method, headers, body }).catch(console.error);
};

export default firebase;
